import './slider.css';
import lightlogo from './../../assets/images/logo_lightbackground.jpg';
import instantcash from './../../assets/images/instantcash.jpeg';
import getrichquick from './../../assets/images/Get rich quick.png';
import nogaurentee from './../../assets/images/noguarantee.png';
// import { useEffect, useRef } from 'react';
// import { gsap } from 'gsap';

function Slider() {
    
    return (
        <div id="main">
        <div id="slider">
            <div className="slide sli1">
                <img src={lightlogo} alt="logo" />
            </div>
            <div className="slide sli2">
                <img src={nogaurentee} alt="logo" />
            </div>
            <div className="slide sli3">
                <img src={instantcash} alt="logo" />
            </div>
        </div>
        </div>
)
}

export default Slider;