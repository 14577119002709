import './body.css';
// import logo from './../../assets/images/logo.jpeg';
import Slider from '../slider/slider';
import Homepage from '../homepage/homepage';
import Faq from '../faq/faq';

function Body() {
    return (      
        <div>  
        <Slider />
        <Homepage />
        <Faq />
        </div>
    )
}

export default Body;