// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.logocontainer img {
    width: 100%;
}

#pagenav {
    width: 100%;
    background-color: black;
    padding: 2em;
}

#navbar, #navbar ul {
    width: 100%;
}

#navbar {
    display: flex;
    justify-content: space-evenly;
}

.navitem {
    list-style: none;
    display: flex;
}

.navitem a {
    color: white;
    font-size: 1.5em;
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/components/navigation/nav.css"],"names":[],"mappings":";;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,uBAAuB;IACvB,YAAY;AAChB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,aAAa;IACb,6BAA6B;AACjC;;AAEA;IACI,gBAAgB;IAChB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,gBAAgB;IAChB,qBAAqB;AACzB","sourcesContent":["\n\n.logocontainer img {\n    width: 100%;\n}\n\n#pagenav {\n    width: 100%;\n    background-color: black;\n    padding: 2em;\n}\n\n#navbar, #navbar ul {\n    width: 100%;\n}\n\n#navbar {\n    display: flex;\n    justify-content: space-evenly;\n}\n\n.navitem {\n    list-style: none;\n    display: flex;\n}\n\n.navitem a {\n    color: white;\n    font-size: 1.5em;\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
