import './faq.css';

function Faq() {
    return (
        <div className="row">
            <div className="column_2"></div>
            <div className="column_8">
                <div className="homepage_intro">
                    <h2>FAQ</h2>
                    <h3>Can I definitely trust you?</h3>
                    <p>Yes you definitely can! Why wouldn't you?</p>
                    <h3>Can I contact you?</h3>
                    <p>No, sorry - we do not provide our contact detail, for the reson of safety and security concern.</p>
                    <h3>This sounds really interesting - can I give you some money now?</h3>
                    <p>Yes you can, we are totally lefitimate and 100% guaranteed backed by financial institution in your country</p>
                </div>
            </div>
            <div className="column_2"></div>
        </div>
    )

}

export default Faq;
