import './App.css';
import Nav from './components/navigation/nav';
import Body from './components/body/body';

function App() {
  
  return (
    <div>
      <Nav />
      <Body />
    </div>
  );
}

export default App;
