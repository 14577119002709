// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#slider {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

/*.slide {
    position: absolute;
    top: 0;
    left: 0;
}*/

.sli1, .sli2, .sli3 {
    width: 500px;
}

.sli1 img, .sli2 img, .sli3 img {
    width: 100%;
    display: flex;
}`, "",{"version":3,"sources":["webpack://./src/components/slider/slider.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;;;;EAIE;;AAEF;IACI,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,aAAa;AACjB","sourcesContent":["#slider {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    position: relative;\n}\n\n/*.slide {\n    position: absolute;\n    top: 0;\n    left: 0;\n}*/\n\n.sli1, .sli2, .sli3 {\n    width: 500px;\n}\n\n.sli1 img, .sli2 img, .sli3 img {\n    width: 100%;\n    display: flex;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
