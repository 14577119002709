// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#main, #article, #aside {
    display: flex;
}

#main {
    width: 100%;
    background-color: #eaeaea;
}

#article {
    width: 75%;
    display: flex;
    justify-content: center;
    align-items: center;
}

#aside {
    width: 25%;
}

.column_1 {
    width: 8.33%;
}

.column_2 {
    width: 16.66%;
}

.column_4 {
    width: 33.33%;
}

.column_8 {
    width: 66.66%;
}

.column_11 {
    width: 91.66%;
}

.column_12 {
    width: 100%;
}

.row {
    width: 100%;
    display: flex;
    align-items: stretch;
}`, "",{"version":3,"sources":["webpack://./src/components/body/body.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;IACX,yBAAyB;AAC7B;;AAEA;IACI,UAAU;IACV,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,UAAU;AACd;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB","sourcesContent":["#main, #article, #aside {\n    display: flex;\n}\n\n#main {\n    width: 100%;\n    background-color: #eaeaea;\n}\n\n#article {\n    width: 75%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n#aside {\n    width: 25%;\n}\n\n.column_1 {\n    width: 8.33%;\n}\n\n.column_2 {\n    width: 16.66%;\n}\n\n.column_4 {\n    width: 33.33%;\n}\n\n.column_8 {\n    width: 66.66%;\n}\n\n.column_11 {\n    width: 91.66%;\n}\n\n.column_12 {\n    width: 100%;\n}\n\n.row {\n    width: 100%;\n    display: flex;\n    align-items: stretch;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
