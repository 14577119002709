// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.column_1 {
    width: 8.33%;
}

.column_2 {
    width: 16.66%;
}

.column_4 {
    width: 33.33%;
}

.column_8 {
    width: 66.66%;
}

.column_11 {
    width: 91.66%;
}

.column_12 {
    width: 100%;
}

.row {
    width: 100%;
    display: flex;
    align-items: stretch;
}

.homepage_intro {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.homepage_intro h1 {
    font-size: 2em;
}

.homepage_intro ul, .homepage_intro ul li {
    list-style: none;
    font-size: 1.2em;
}`, "",{"version":3,"sources":["webpack://./src/components/faq/faq.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,WAAW;IACX,aAAa;IACb,oBAAoB;AACxB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":[".column_1 {\n    width: 8.33%;\n}\n\n.column_2 {\n    width: 16.66%;\n}\n\n.column_4 {\n    width: 33.33%;\n}\n\n.column_8 {\n    width: 66.66%;\n}\n\n.column_11 {\n    width: 91.66%;\n}\n\n.column_12 {\n    width: 100%;\n}\n\n.row {\n    width: 100%;\n    display: flex;\n    align-items: stretch;\n}\n\n.homepage_intro {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.homepage_intro h1 {\n    font-size: 2em;\n}\n\n.homepage_intro ul, .homepage_intro ul li {\n    list-style: none;\n    font-size: 1.2em;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
