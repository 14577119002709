import './homepage.css';

function Homepage() {
    return (
        <div className="row">
            <div className="column_2"></div>
            <div className="column_8">
                <div className="homepage_intro">
                    <h1>Why U Can Definitely Trust Me</h1>
                    <br />
                    <ul>
                        <li>&#x2705; Over 100 years of combined experience in making people like you rich!</li>
                        <li>&#x2705; Simple registration process - just your name, address and bank details required!</li>
                        <li>&#x2705; Over 1000 satisfied clients and investors!</li>
                    </ul>
                </div>
            </div>
            <div className="column_2"></div>
        </div>
    )

}

export default Homepage;
