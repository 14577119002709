import './nav.css';
import logo from './../../assets/images/logo.jpeg';

function Nav() {
    return (
        <div className="row">
            <div className="column_1 logocontainer">
                <img src={logo} alt="ucandefinitelytrustmelogo" />
            </div>
            <div className="column_11">
                <div id="pagenav">
                    <ul id="navbar">
                        <li className="navitem"><a href="#">Home</a></li>
                        <li className="navitem"><a href="#">Store</a></li>
                        <li className="navitem"><a href="#">About</a></li>
                        <li className="navitem"><a href="#">GET RICH NOW!</a></li>
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Nav;